import React from "react";
import "./NavBar.css";
import { Link } from "react-scroll";

function NavBar() {
  return (
    <div className="navbar-wrapper">
      <div className="navbar-logo-container">
        <Link
          to="introduction"
          smooth={true}
          spy={true}
          offset={-150}
          duration={1000}
        >
          <img
            className="logo"
            src="https://res.cloudinary.com/brnl/image/upload/v1658230049/brnl/ms-logo_byklgl.svg"
            alt="logo"
          ></img>
        </Link>
      </div>

      <div className="navbar-options-container">
        <Link
          to="skills"
          smooth={true}
          spy={true}
          offset={-50}
          duration={1000}
          className="navbar-options underlined"
        >
          Skills
        </Link>
        <Link
          to="projects"
          smooth={true}
          spy={true}
          offset={0}
          duration={1000}
          className="navbar-options underlined"
        >
          Work
        </Link>
        <Link
          to="aboutme"
          smooth={true}
          spy={true}
          offset={0}
          duration={1000}
          className="navbar-options underlined"
        >
          About me
        </Link>
        <Link
          to="contact"
          smooth={true}
          spy={true}
          offset={0}
          duration={1500}
          className="navbar-options underlined"
        >
          Contact
        </Link>
        <div className="navbar-options underlined">
          <a
            href={require("../Media/resume.pdf")}
            rel="noreferrer"
            target="_blank"
          >
            {" "}
            Resume
          </a>
        </div>
        <div className="navbar-options icons">
          {/* <span>Contact:</span> */}
        </div>
      </div>
    </div>
  );
}

export default NavBar;
