import React from "react";
import "./Contact.css";
import { motion } from "framer-motion";

function Contact() {
  return (
    <section className="contact-section" id="contact">
      <div className="skills-title">
        <div className="my-skills-title">Contact Me</div>
      </div>

      <div className="contact-wrapper">
        <motion.div className="contact-image-container">
          <img
            className="contact-image"
            src={require("../Media/Contactme.gif")}
            alt=""
          ></img>
        </motion.div>
        <div className="contact-text">
          <h2>Let's have a chat?</h2>
          <h3>You can find me on:</h3>
          <div className="contact-icons">
            <div className="contact ">
              <a
                href="https://www.linkedin.com/in/mauricioscain/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="icon"
                  alt="LinkedIn"
                  src="https://res.cloudinary.com/brnl/image/upload/v1658244645/brnl/linkedin_qxqrma.png"
                ></img>{" "}
              </a>
            </div>
            <div className="contact">
              <a
                href="https://github.com/scainMauricio"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <img
                  className="icon"
                  alt=""
                  src="https://res.cloudinary.com/brnl/image/upload/v1658244645/brnl/github_nprruk.png"
                ></img>{" "}
              </a>
            </div>
            <div className="contact">
              <a href="mailto:scainmauricio@gmail.com">
                {" "}
                <img
                  className="icon"
                  alt=""
                  src="https://res.cloudinary.com/brnl/image/upload/v1658244645/brnl/gmail_oen2nt.png"
                ></img>{" "}
              </a>
            </div>
          </div>

          <p>
            More information about me on my{" "}
            <span>
              <a
                href={require("../Media/resume.pdf")}
                rel="noreferrer"
                target="_blank"
              >
                {" "}
                Resume.
              </a>
            </span>
          </p>
        </div>
      </div>
    </section>
  );
}

export default Contact;
