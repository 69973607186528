import React from "react";
import "./Projects.css";
import { motion } from "framer-motion";

function Projects() {
  return (
    <section className="project-section" id="projects">
      <div className="skills-title">
        <div className="my-skills-title">My Work</div>
      </div>
      <div className="project-container">
        <div className="project-name">Helper Duck</div>
        <div className="project-info-container">
          <div className="project-info">
            <div className="project-role">Fullstack Developer</div>
            <div className="project-techstack">
              <div className="project-tech">
                <ul className="skill-ul-project">
                  <li className="skill-li">
                    JavaScript<span className="tooltip">fullstack</span>
                  </li>

                  <li className="skill-li">
                    TypeScript<span className="tooltip">fullstack</span>
                  </li>
                  <li className="skill-li">
                    React <span className="tooltip">frontend</span>
                  </li>
                  <li className="skill-li">
                    Redux <span className="tooltip">frontend</span>
                  </li>
                  <li className="skill-li">
                    PostgreSQL<span className="tooltip">database</span>
                  </li>
                  <li className="skill-li">
                    Prisma<span className="tooltip">database</span>
                  </li>
                  <li className="skill-li">
                    Express<span className="tooltip">backend</span>
                  </li>
                  <li className="skill-li">
                    NodeJS<span className="tooltip">backend</span>
                  </li>
                  <li className="skill-li">
                    HTML<span className="tooltip">frontend</span>
                  </li>
                  <li className="skill-li">
                    CSS<span className="tooltip">frontend</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="project-about">
              <ul className="role-ul">
                <li>
                  Worked in a <span>team</span> to develop the web app from an
                  idea to a <span>fully functional app.</span>
                </li>
                <li> Contributed to the product design</li>
                <li>
                  Developed the front-end in <span>React</span> according to the
                  product design.
                </li>
                <li>Built the communication between from and backend.</li>
                <li>
                  Implemented <span>Redux</span> for state management.{" "}
                </li>

                <li>
                  Implemented <span>Express NodeJS</span> server.{" "}
                </li>
                <li>Implemented Routes and Controllers.</li>
              </ul>
            </div>
          </div>

          <div className="project-info">
            <div className="project-image-container">
              <motion.img
                className="project-image"
                alt=""
                src="https://res.cloudinary.com/brnl/image/upload/v1657617643/brnl/helper-duck-dashboard_vznyvw.jpg"
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{
                  type: "spring",
                  bounce: 0.35,
                  ease: "easeOut",
                  duration: 3,
                }}
              ></motion.img>
            </div>{" "}
            <div className="project-description">
              Helper Duck is a platform where developers can help each other by
              sharing knowledge through 1-on-1 help requests.
            </div>
            <div className="project-btn-container">
              <a
                href="https://helperduck.com/home"
                target="_blank"
                className="project-btn"
                rel="noreferrer"
              >
                View Website
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="project-container">
        <div className="project-name">Unwind Time</div>
        <div className="project-info-container">
          <div className="project-info">
            <div className="project-role">Fullstack Developer</div>
            <div className="project-techstack">
              <div className="project-tech">
                <ul className="skill-ul-project">
                  <li className="skill-li">
                    JavaScript<span className="tooltip">fullstack</span>
                  </li>

                  <li className="skill-li">
                    TypeScript<span className="tooltip">fullstack</span>
                  </li>
                  <li className="skill-li">
                    React <span className="tooltip">frontend</span>
                  </li>
                  <li className="skill-li">
                    Redux <span className="tooltip">frontend</span>
                  </li>
                  <li className="skill-li">
                    Firebase<span className="tooltip">database</span>
                  </li>

                  <li className="skill-li">
                    Cypress.io<span className="tooltip">backend</span>
                  </li>

                  <li className="skill-li">
                    HTML<span className="tooltip">frontend</span>
                  </li>
                  <li className="skill-li">
                    CSS<span className="tooltip">frontend</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="project-about">
              <ul className="role-ul">
                <li>
                  Refactored the entire app to <span>TypeScript</span>.
                </li>
                <li> Contributed to the product design</li>
                <li>Bug handling and Bug fixing.</li>
                <li>
                  Implemented <span>CSS</span>.
                </li>
                <li>
                  Implemented End-to-End testing with <span>Cypress.io</span>.
                </li>
              </ul>
            </div>
          </div>

          <div className="project-info">
            <div className="project-image-container">
              <motion.img
                className="project-image"
                alt=""
                src="https://res.cloudinary.com/brnl/image/upload/v1657621675/brnl/unwind_curxbv.jpg"
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{
                  type: "spring",
                  bounce: 0.35,
                  ease: "easeOut",
                  duration: 3,
                }}
              ></motion.img>
            </div>{" "}
            <div className="project-description">
              On Unwindtime you can match with people that want to go for a
              small break and would like to do some activity with someone else.
            </div>
            <div className="project-btn-container">
              <a
                href="https://unwind-time.web.app"
                target="_blank"
                className="project-btn"
                rel="noreferrer"
              >
                View Website
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Projects;
