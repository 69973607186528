import React from "react";
import "./AboutMe.css";
import { motion } from "framer-motion";

function AboutMe() {
  return (
    <section className="aboutme-section" id="aboutme">
      <div className="skills-title">
        <div className="my-skills-title">About me</div>
      </div>
      <div className="aboutme-wrapper">
        <div className="aboutme-text">
          <p>
            My name is <span>Mauricio</span> and I enjoy creating things for the
            web.
          </p>
          <p>
            <span>Brazilian</span> living in - and loving - the{" "}
            <span>Netherlands.</span>{" "}
          </p>
          <p>
            I've always been interested in everything{" "}
            <span>
              Tech<span></span> related
            </span>
            . I had my first experience with coding when I was 16{" "}
            <span>building</span>
            <span> websites</span> for my favourite games.
          </p>
          <p>
            I take <span>learning</span> as a hobby. I am{" "}
            <span>continuously </span>
            <span> improving</span> the skills I already have and I am always{" "}
            <span> keen</span>
            <span> to</span>
            <span> learn</span> new ones.
          </p>
          <p>
            As a <span>professional</span>, I'm a <span>hard</span>
            <span> worker</span>. You can count on me for any challenge. I
            recently had an intense - and amazing - <span>experience</span>{" "}
            <span>coding </span> 12 hours a day with a group of dedicated
            developers.
          </p>
          <p>
            As a <span>colleague</span>, I am a <span>team</span>{" "}
            <span>player</span>. I really enjoy the exchange of knowledge and
            experiences. I love helping my colleagues and I am always open to
            listening and learning too.
          </p>
          <br></br>
        </div>

        <motion.div className="aboutme-image-container">
          <img
            className="aboutme-image"
            src={require("../Media/aboutme.gif")}
            alt=""
          ></img>
        </motion.div>
      </div>
    </section>
  );
}

export default AboutMe;
