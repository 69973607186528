import "./App.css";

import Introduction from "./Components/Introduction";
import NavBar from "./Components/NavBar";
import Skills from "./Components/Skills";
import Projects from "./Components/Projects";
import AboutMe from "./Components/AboutMe";
import Contact from "./Components/Contact";
function App() {
  return (
    <div className="App">
      <NavBar></NavBar>
      <Introduction></Introduction>
      <Skills></Skills>
      <Projects></Projects>
      <AboutMe></AboutMe>
      <Contact></Contact>
    </div>
  );
}

export default App;
