import React from "react";
import "./Introduction.css";
import { motion } from "framer-motion";
import TextEffect from "./TextEffect";

function Introduction() {
  const line1 = "Hello, my name is Mauricio.";
  return (
    <section className="introduction-section" id="introduction">
      <div className="introduction-wrapper">
        <div className="introduction-text">
          {/* <TextEffect
            text={line1}
            delay={2}
            classN={"introduction-line line1"}
          ></TextEffect> */}
          <motion.p
            className="introduction-line line1"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              type: "tween",
              ease: "easeIn",
              duration: 2,
              delay: 3,
            }}
          >
            Hello, my name is <span>Mauricio.</span>
          </motion.p>

          <motion.p
            className="introduction-line line2"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              type: "tween",
              ease: "easeIn",
              duration: 2,
              delay: 5,
            }}
          >
            A Full Stack Developer.
          </motion.p>
          <motion.p
            className="introduction-line line3"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              type: "tween",
              ease: "easeIn",
              duration: 2,
              delay: 5,
            }}
          >
            Bringing ideas to life with code.
          </motion.p>
        </div>

        <motion.div
          className="introduction-image-container"
          initial={{ y: -100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            type: "spring",
            bounce: 0.35,
            ease: "easeOut",
            duration: 3,
          }}
        >
          <img
            className="introduction-image"
            src={require("../Media/CodeBoy.gif")}
            alt=""
          ></img>
        </motion.div>
      </div>
    </section>
  );
}

export default Introduction;
