import React from "react";
import "./Skills.css";
import { motion } from "framer-motion";

function Skills() {
  return (
    <section className="skills-section" id="skills">
      <div className="skills-title">
        <div className="my-skills-title">My Skills</div>
      </div>
      <div className="skills-outer-container">
        <div className="skills-about-wrapper">
          <div>
            <img
              className="skills-image"
              src={require("../Media/Coding.gif")}
              alt=""
            ></img>
          </div>
        </div>
        <div className="skills-wrapper">
          <div className="skill-tech-title">My main Tech Stack</div>
          <div className="skill-main-tech">
            <ul className="skill-ul">
              <li className="skill-li">
                JavaScript<span className="tooltip">fullstack</span>
              </li>

              <li className="skill-li">
                TypeScript<span className="tooltip">fullstack</span>
              </li>
              <li className="skill-li">
                React <span className="tooltip">frontend</span>
              </li>
              <li className="skill-li">
                Redux <span className="tooltip">frontend</span>
              </li>
              <li className="skill-li">
                MongoDB<span className="tooltip">database</span>
              </li>
              <li className="skill-li">
                Mongoose<span className="tooltip">database</span>
              </li>
              <li className="skill-li">
                Express<span className="tooltip">backend</span>
              </li>
              <li className="skill-li">
                NodeJS<span className="tooltip">backend</span>
              </li>
              <li className="skill-li">
                HTML<span className="tooltip">frontend</span>
              </li>
              <li className="skill-li">
                CSS<span className="tooltip">frontend</span>
              </li>
            </ul>
          </div>
          <div className="skill-tech-title">Experience with</div>
          <div className="skill-sec-tech">
            <ul className="skill-ul">
              <li className="skill-li">
                Angular<span className="tooltip">frontend</span>
              </li>
              <li className="skill-li">
                Koa<span className="tooltip">backend</span>
              </li>
              <li className="skill-li">
                PostgreSQL<span className="tooltip">database</span>
              </li>
              <li className="skill-li">
                GraphQL<span className="tooltip">fullstack</span>
              </li>
              <li className="skill-li">
                Sequelize<span className="tooltip">backend</span>
              </li>
              <li className="skill-li">
                Prisma<span className="tooltip">backend</span>
              </li>
              <li className="skill-li">
                Socket.io<span className="tooltip">fullstack</span>
              </li>
              <li className="skill-li">
                Cypress.io<span className="tooltip">testing</span>
              </li>
              <li className="skill-li">
                Firebase<span className="tooltip">backend</span>
              </li>
              <li className="skill-li">Git</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Skills;
